"use client";
/**
 * NOTE: This wrapper is needed because `ApolloNextAppProvider` must be passed a function, which means that it can not be initialized by the
 * client hook `apolloMakeClient` in a server context.  Moving this code here allows `App.tsx` to be initialized in a server context.
 */

import { ApolloNextAppProvider } from "@apollo/experimental-nextjs-app-support";
import { createInstance, OptimizelyProvider } from "@optimizely/react-sdk";
import { useAppAnalytics } from "@wojo/analytics";
import { apolloMakeClient } from "@wojo/apollo";
import { AuthContextProvider, AuthModal } from "@wojo/auth";
import { useSendGtmOptimizelyEvent } from "@wojo/services";
import {
    initLogRocket,
    logRocketOnError,
    useLogRocketIdentify,
} from "@wojo/web-components";
import { useWebConfigSuspenseQuery } from "client/hooks";
import { authGuardedPaths, omitPaths } from "constants/auth-guarded-paths";
import Cookies from "js-cookie";
import { useEffect } from "react";

const optimizely = createInstance({
    sdkKey: process.env.NEXT_PUBLIC_OPTIMIZELY_SDK_KEY,
    logLevel: "error",
});

export const SiteClientWrapper: React.FC<
    React.PropsWithChildren<{
        cookie?: string;
        isLoggedIn: boolean;
        optimizelyUserId: string;
    }>
> = ({ children, cookie, isLoggedIn, optimizelyUserId }) => {
    const webConfig = useWebConfigSuspenseQuery();
    useEffect(() => {
        Cookies.set("optimizely-user-id", optimizelyUserId, {
            expires: 365,
            secure: true,
        });
    }, [optimizelyUserId]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const promo = urlParams.get("promo");

        if (promo) {
            Cookies.set("promo", promo, {
                expires: 30,
            });

            urlParams.delete("promo");
            const newUrl = `${
                window.location.pathname
            }?${urlParams.toString()}`;
            window.history.replaceState(null, "", newUrl);
        }
    }, []);

    return (
        <ApolloNextAppProvider
            makeClient={() =>
                apolloMakeClient({
                    cookie,
                    logRocketOnError,
                    initLogRocket: () => initLogRocket(),
                })
            }
        >
            <OptimizelyProvider
                optimizely={optimizely}
                user={{ id: optimizelyUserId }}
            >
                <AuthContextProvider
                    authGuardedPaths={authGuardedPaths}
                    isLoggedIn={isLoggedIn}
                    webSource="INDIES"
                    omitPaths={omitPaths}
                    smsOptInDefault={webConfig.smsOptInDefault}
                >
                    <SiteClientWrapperInner>{children}</SiteClientWrapperInner>
                    <AuthModal />
                </AuthContextProvider>
            </OptimizelyProvider>
        </ApolloNextAppProvider>
    );
};
export const SiteClientWrapperInner: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    useAppAnalytics();
    useLogRocketIdentify();
    useSendGtmOptimizelyEvent("urgency_badges");

    return <>{children}</>;
};
