import React from "react";
import { Markdown } from "@wojo/ui";
import { useI18n } from "@wojo/localization";

export type ReviewItemTourInfoProps = {
    formattedTravelDate: string | null;
    tourName: string | null | undefined;
    tourCode: string | undefined;
    className?: string;
};

export const ReviewItemTourInfo: React.FC<ReviewItemTourInfoProps> = ({
    formattedTravelDate,
    tourName,
    tourCode,
    className,
}) => {
    const i18n = useI18n("storyblok").storyblok.reviewItem;
    if (!formattedTravelDate && !tourName) {
        return null;
    }

    if (formattedTravelDate && tourName) {
        return (
            <Markdown
                className={className}
                markdown={i18n.traveledInAndOn.l({
                    date: formattedTravelDate,
                    tourLink: tourName,
                    tourUrl: `/${tourCode}`,
                })}
            />
        );
    }
    if (formattedTravelDate) {
        return (
            <Markdown
                className={className}
                markdown={i18n.traveledIn.l({
                    date: formattedTravelDate,
                })}
            />
        );
    }
    if (tourName) {
        return (
            <Markdown
                className={className}
                markdown={i18n.traveledOn.l({
                    tourLink: tourName,
                    tourUrl: `/${tourCode}`,
                })}
            />
        );
    }
};
