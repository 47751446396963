"use client";

import { useI18n } from "@wojo/localization";
import { Button, Rating, Text } from "@wojo/ui";
import dayjs from "dayjs";
import ordinal from "ordinal";
import React, { useId, useRef, useState } from "react";
import { ReviewsAggregateQuery } from "../../../../generated/graphql";
import { SchemaScript } from "../../../components/schema-script";
import { ReviewImages } from "../review-images/ReviewImages";
import { ReviewModal } from "../review-modal/ReviewModal";
import styles from "./ReviewItem.module.scss";
import { ReviewItemTourInfo } from "../../../components/review-item-tour-info";

export type ReviewItemProps = {
    review: ReviewsAggregateQuery["reviews"]["results"][number];
    reviewStyle: "with-tour-name" | "without-tour-name";
};

const truncateToNearestWord = (
    body: string,
    maxDescriptionLength: number,
): string => {
    if (body.length <= maxDescriptionLength) {
        return body;
    }

    let lastSpaceIndex = -1;
    for (let i = 0; i < maxDescriptionLength; i++) {
        if (body[i].match(/\s/)) {
            lastSpaceIndex = i;
        }
    }

    if (lastSpaceIndex === -1) {
        return `${body.slice(0, maxDescriptionLength)}...`;
    } else {
        return `${body.slice(0, lastSpaceIndex)}...`;
    }
};

export const ReviewItem: React.FC<ReviewItemProps> = ({
    review,
    reviewStyle,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [showFullDetail, setShowFullDetail] = useState(false);
    const [showFullResponse, setShowFullResponse] = useState(false);
    const responseBodyRef = useRef<HTMLDivElement>(null);
    const titleId = useId();
    const i18n = useI18n("storyblok").storyblok;
    const detailTitle = review.detail?.title || "";
    const detailBody = review.detail?.body || "";

    if (!review.rating) {
        return null;
    }

    const travelDate = review.travelDate
        ? dayjs(review.travelDate).format("MMM YYYY")
        : null;

    const truncatedDetailTitle =
        detailTitle.length > 60
            ? detailTitle.substring(0, 60) + "..."
            : detailTitle;

    let detailAuthor = i18n.reviewsList.authorDetail.l({
        ordinalTripCount: review.nbPastTrips
            ? i18n.reviewsList.ordinalTripCount.l({
                  ordinalNumber: ordinal(review.nbPastTrips),
              })
            : "",
        name: review.detail?.author
            ? i18n.reviewsList.authorName.l({ name: review.detail.author })
            : i18n.reviewsList.anonymousTravelerLabel.l(),
    });

    if (reviewStyle === "without-tour-name" && travelDate) {
        detailAuthor += i18n.reviewsList.traveledIn.l({ travelDate });
    }

    detailAuthor = detailAuthor.trim();
    detailAuthor = detailAuthor.charAt(0).toUpperCase() + detailAuthor.slice(1);

    const maxDetailBodyLength = 280;
    const truncatedDetailBody = truncateToNearestWord(
        detailBody,
        maxDetailBodyLength,
    );

    const maxResponseBodyLength = 140;
    const truncatedResponseBody = truncateToNearestWord(
        review.detail?.response?.body ?? "",
        maxResponseBodyLength,
    );

    const tourName = review.tour?.content?.name;
    const truncatedTourName =
        tourName && tourName.length > 60
            ? tourName.substring(0, 60) + "..."
            : tourName;

    const productUrl = `/${review.tourCode}`;
    return (
        <div
            className={styles["review-wrapper"]}
            aria-labelledby={truncatedDetailTitle ? titleId : undefined}
            aria-label={
                truncatedDetailTitle
                    ? undefined
                    : i18n.reviewsList.a11y.ariaLabel.l()
            }
        >
            <div>
                {!!tourName && (
                    <SchemaScript
                        data={{
                            "@type": "Review",
                            "@context": "https://schema.org",
                            "@id": `list-${review._id}`,
                            reviewRating: {
                                "@type": "Rating",
                                ratingValue: review.rating,
                                bestRating: 5,
                            },
                            reviewBody: detailBody,
                            name: detailTitle,
                            itemReviewed: {
                                "@type": "Product",
                                name: tourName,
                                url: productUrl,
                                review: {
                                    "@id": `list-${review._id}`,
                                },
                            },
                            author: {
                                "@type": "Person",
                                name:
                                    review.detail?.author ??
                                    i18n.reviewsList.anonymousTravelerLabel.l(),
                            },
                        }}
                    />
                )}
                {truncatedDetailTitle && (
                    <Text.Heading
                        id={titleId}
                        order="5"
                        tag="div"
                        className={styles["detail-title"]}
                    >
                        {truncatedDetailTitle}
                    </Text.Heading>
                )}
                <div className={styles["rating-and-author"]}>
                    {review.rating && (
                        <Rating
                            value={review.rating}
                            variant="standard"
                            size="small"
                        />
                    )}
                    <Text.Body variant="2">{detailAuthor}</Text.Body>
                </div>
                {detailBody && (
                    <Text.Body className={styles["detail-body"]}>
                        {showFullDetail ? detailBody : truncatedDetailBody}
                        {!showFullDetail && (
                            <>
                                <span className={styles.spacer}> </span>
                                {detailBody.length > maxDetailBodyLength ? (
                                    <Button
                                        onClick={() => {
                                            setShowFullDetail(true);
                                        }}
                                        variant="quiet"
                                    >
                                        Read more{" "}
                                        {!!detailTitle && (
                                            <span className="visually-hidden">
                                                of {detailTitle}
                                            </span>
                                        )}
                                    </Button>
                                ) : null}
                            </>
                        )}
                    </Text.Body>
                )}
                {review.images.length > 0 && (
                    <ReviewImages
                        images={review.images}
                        reviewName={detailTitle}
                        setSelectedImageIndex={setSelectedImageIndex}
                        setIsModalOpen={setIsModalOpen}
                    />
                )}
                {reviewStyle === "with-tour-name" &&
                    (travelDate || truncatedTourName) && (
                        <ReviewItemTourInfo
                            tourCode={review.tourCode}
                            tourName={truncatedTourName}
                            formattedTravelDate={travelDate}
                            className={styles["travel-date-and-product-link"]}
                        />
                    )}
            </div>
            {review.detail?.response?.body && (
                <div
                    aria-label={`Reply to ${truncatedDetailTitle || "review"}`}
                    className={styles.response}
                    ref={responseBodyRef}
                    tabIndex={-1}
                >
                    {review.detail.response.author && (
                        <Text.Body emphasis="2">
                            {review.detail.response.author}
                        </Text.Body>
                    )}
                    {showFullResponse ? (
                        <Text.Body>{review.detail.response.body}</Text.Body>
                    ) : (
                        <Text.Body>
                            {truncatedResponseBody}
                            <span className={styles.spacer}> </span>
                            {review.detail.response.body.length >
                            maxResponseBodyLength ? (
                                <Button
                                    onClick={() => {
                                        setShowFullResponse(true);
                                        responseBodyRef.current?.focus();
                                    }}
                                    variant="quiet"
                                >
                                    Read more{" "}
                                    {!!review.detail.title && (
                                        <span className="visually-hidden">
                                            of the response to{" "}
                                            {review.detail.title}
                                        </span>
                                    )}
                                </Button>
                            ) : null}
                        </Text.Body>
                    )}
                </div>
            )}
            <ReviewModal
                isOpen={isModalOpen}
                selectedImageIndex={selectedImageIndex}
                onCloseRequested={() => setIsModalOpen(false)}
                review={review}
            />
        </div>
    );
};
